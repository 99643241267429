import http from "./http.service"

http.setToken(getToken())

async function login(payload) {
  const { data } = await http.post(process.env.REACT_APP_API_URL + '/admin_login', payload)
  return data
}

// async function register(payload) {
//   const { data } = await http.post(process.env.REACT_APP_API_URL + '/register', payload)
//   return data
// }

// async function sendVerificationCode(payload) {
//   const { data } = await http.post(process.env.REACT_APP_API_URL + `/send_verification_code`, payload)
//   return data
// }

// async function logout() {
//   localStorage.clear()
// }

function getToken() {
  return localStorage.getItem('token')
}

const authService = {
  login,
//   register,
//   sendVerificationCode,
//   logout,
  getToken,
};

export default authService
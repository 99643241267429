import React from 'react'
import { VscFilePdf, VscFileZip, VscFile } from 'react-icons/vsc'

const BYTE = 1000
const getKB = (bytes) => Math.round(bytes / BYTE)

const FileIcon = ({ children }) => {
    return <span className="text-4xl">{children}</span>
}

const FileItem = (props) => {
    const { file, children, customise, datatable } = props
    const { type, name, size } = file

    const renderThumbnail = () => {
        const isImageFile = type.split('/')[0] === 'image'

        if (isImageFile) {
            return (
                <img
                    className="upload-file-image"
                    src={URL.createObjectURL(file)}
                    alt={`file preview ${name}`}
                />
            )
        }

        if (type === 'application/zip') {
            return (
                <FileIcon>
                    <VscFileZip />
                </FileIcon>
            )
        }

        if (type === 'application/pdf') {
            return (
                <FileIcon>
                    <VscFilePdf />
                </FileIcon>
            )
        }

        return (
            <FileIcon>
                <VscFile />
            </FileIcon>
        )
    }

    return (
        <div className={`${datatable ? 'datatable-file' :  "upload-file" } ${customise && !datatable ? 'max-w-[300px]' : ''}`}>
            <div className="flex">
                {!customise ? <div className="upload-file-thumbnail">{renderThumbnail()}</div> : ''}
                <div className="upload-file-info">
                    <h6 className={!customise ? "upload-file-name" : "text-sm"}>{name}</h6>
                    <span className={!customise ? "upload-file-size" : "text-xs mt-1"}>{getKB(size)} kb</span>
                </div>
            </div>
            {children}
        </div>
    )
}

export default FileItem

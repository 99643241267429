import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiService from "../../services/api.service";

export const getOrderReport = createAsyncThunk('getOrderReport', async (payload, thunkAPI) => {
  try {
    const response = await apiService.getOrderReport(payload);
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }}
);

export const getCommissionReport = createAsyncThunk('getCommissionReport', async (payload, thunkAPI) => {
  try {
    const response = await apiService.getCommissionReport(payload);
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }}
);

export const getCommissionHistoryReport = createAsyncThunk('getCommissionHistoryReport', async (payload, thunkAPI) => {
  try {
    const response = await apiService.getCommissionReport(payload);
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }}
);

export const getInstallerCommissionHistoryReport = createAsyncThunk('getInstallerCommissionHistoryReport', async (payload, thunkAPI) => {
  try {
    const response = await apiService.getInstallerCommissionReport(payload);
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }}
);

export const getOrderDetails = createAsyncThunk('getOrderDetails', async (payload, thunkAPI) => {
  try {
    const response = await apiService.getOrderDetails(payload);
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }}
);

export const updateOrderReport = createAsyncThunk('updateOrderReport', async (payload, thunkAPI) => {
  try {
    const response = await apiService.updateOrderReport(payload);
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }}
);

export const initialData = {
  reportList: [],
  reportHistoryList: [],
  reportInfo: {},
  actionSlip: {},

  infoLoading: false,
  listLoading: true,
  updateLoading: false,

  actionStatus: {},
  isOpenActionDialog: false,

  reportSort: {
    activePage: 1,
    start: 0,
    length: 10,
    search: '',
    total: 0,
    type: 'agent',
    payment_slip: 'no'
  },

  reportHistorySort: {
    activePage: 1,
    start: 0,
    length: 5,
    search: '',
    total: 0,
    type: localStorage.getItem('role') === 'salesman' ? 'salesman' : 'agent',
    payment_slip: 'yes'
  },
}

const dataSlice = createSlice({
  name: "report/data",
  initialState: initialData,
  reducers: {
    openActionDialog: (state) => {
      state.isOpenActionDialog = true;
    },
    closeActionDialog: (state) => {
      state.isOpenActionDialog = false;
    },
    setActionSlip: (state, action) => {
      state.actionSlip = action.payload;
    },
    setActionStatus: (state, action) => {
      state.actionStatus = action.payload;
    },
    setReportSort: (state, action) => {
      state.reportSort = action.payload;
    },
    setReportHistorySort: (state, action) => {
      state.reportHistorySort = action.payload;
    },
    setListLoading: (state, action) => {
      state.listLoading = action.payload;
    },
  },
  extraReducers: {
    //list
    [getOrderReport.rejected]: (state) => {
      state.listLoading = false;
    },
    [getOrderReport.fulfilled]: (state, action) => {
      state.listLoading = false;
      state.reportList = action.payload.data.aaData;
      state.reportSort.total = action.payload.data.iTotalDisplayRecords;
    },
    [getOrderReport.pending]: (state) => {
      state.listLoading = true;
    },

    [getCommissionReport.rejected]: (state) => {
      state.listLoading = false;
    },
    [getCommissionReport.fulfilled]: (state, action) => {
      state.listLoading = false;
      state.reportList = action.payload.data.aaData;
      state.reportSort.total = action.payload.data.iTotalDisplayRecords;
    },
    [getCommissionReport.pending]: (state) => {
      state.listLoading = true;
    },

    [getCommissionHistoryReport.rejected]: (state) => {
      state.historyListLoading = false;
    },
    [getCommissionHistoryReport.fulfilled]: (state, action) => {
      state.historyListLoading = false;
      state.reportHistoryList = action.payload.data.aaData;
      state.reportHistorySort.total = action.payload.data.iTotalDisplayRecords;
    },
    [getCommissionHistoryReport.pending]: (state) => {
      state.historyListLoading = true;
    },

    [getInstallerCommissionHistoryReport.rejected]: (state) => {
      state.historyListLoading = false;
    },
    [getInstallerCommissionHistoryReport.fulfilled]: (state, action) => {
      state.historyListLoading = false;
      state.reportHistoryList = action.payload.data.aaData;
      state.reportHistorySort.total = action.payload.data.iTotalDisplayRecords;
    },
    [getInstallerCommissionHistoryReport.pending]: (state) => {
      state.historyListLoading = true;
    },


    //info
    [getOrderDetails.rejected]: (state) => {
      state.infoLoading = false;
    },
    [getOrderDetails.fulfilled]: (state, action) => {
      state.infoLoading = false;
      state.reportInfo = action.payload.data;
    },
    [getOrderDetails.pending]: (state) => {
      state.infoLoading = true;
    },

    //update
    [updateOrderReport.rejected]: (state) => {
      state.updateLoading = false;
    },
    [updateOrderReport.fulfilled]: (state, action) => {
      state.updateLoading = false;
    },
    [updateOrderReport.pending]: (state) => {
      state.updateLoading = true;
    },
  }
});

export const { 
  openActionDialog,
  closeActionDialog,
  setActionSlip,
  setActionStatus,
  setReportSort,
  setReportHistorySort
} = dataSlice.actions;

export default dataSlice.reducer;


import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import authService from "services/auth.service";

export const login = createAsyncThunk('login', async (payload, thunkAPI) => {
  try {
    const response = await authService.login(payload);
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }}
);

export const initialData = {
  token: '',
  username: '',
  role: [],
  accessRole: '',
  confirmData: {},
  isOpenConfirmDialog: false
};

const dataSlice = createSlice({
  name: "common",
  initialState: initialData,
  reducers: {
    getToken: (state) => {
      state.token = localStorage.getItem("token");
    },
    getUsername: (state) => {
      state.username = localStorage.getItem("username");
    },
    getRole: (state) => {
      state.role = [localStorage.getItem("role")];
      state.accessRole = localStorage.getItem("role")
    },
    openConfirmDialog: (state) => {
      state.isOpenConfirmDialog = true;
    },
    closeConfirmDialog: (state) => {
      state.isOpenConfirmDialog = false;
    },
    setConfirmData: (state, action) => {
      state.confirmData = action.payload;
    },
  },
  extraReducers: {
    [login.rejected]: (state) => {
      state.loginLoading = false;
    },
    [login.fulfilled]: (state, action) => {
      state.loginLoading = false;
    },
    [login.pending]: (state) => {
      state.loginLoading = true;
    },
  },
});

export const { 
  getToken,
  getUsername,
  getRole,
  openConfirmDialog,
  closeConfirmDialog,
  setConfirmData
} = dataSlice.actions;

export default dataSlice.reducer;


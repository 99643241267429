import { useMemo } from 'react'
import isEmpty from 'lodash/isEmpty'
import { useSelector } from 'react-redux'

function useAuthority(userAuthority = [], authority = [], emptyCheck = false) {
    const { role: roles } = useSelector((state) => state.common)

    const roleMatched = useMemo(() => {
        return authority.some((role) => roles.includes(role))
    }, [authority, userAuthority])

    if (
        isEmpty(authority) ||
        isEmpty(roles) ||
        typeof authority === 'undefined'
    ) {
        return !emptyCheck
    }

    return roleMatched
}

export default useAuthority

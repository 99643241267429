import { combineReducers } from 'redux'
import theme from './theme/themeSlice'
import auth from './auth'
import base from './base'
import locale from './locale/localeSlice'
import common from './commonReducer'
import service from './serviceReducer'
import user from './userReducer'
import cart from './cartReducer'
import report from './reportReducer'
import job from './jobReducer'

const rootReducer = (asyncReducers) => (state, action) => {
    const combinedReducer = combineReducers({
        theme,
        auth,
        base,
        locale,
        common,
        service,
        user,
        cart,
        report,
        job,
        ...asyncReducers,
    })
    return combinedReducer(state, action)
}

export default rootReducer
